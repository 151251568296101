<template>
  <!-- 推荐产品 -->
  <article class="recommended row-between core">
    <div class="recommended-l pr mr10">
      <el-image
        :src="require('@/assets/recommended.png')"
        fit="contain"
        lazy
        class="bg bg-primary"
      ></el-image>
      <!-- <el-image :src="config.recommended_products"   fit="fill"></el-image> -->
      <!-- <img class="praise pa" src="@/assets/icon/icon-praise.png" alt="" /> -->
      <div class="info pa white">
        <p class="title" style="color: red">推荐产品</p>
        <p class="sub-title lg" style="color: red">Recommended Products</p>
      </div>
    </div>
    <div class="recommended-r flex1">
      <div class="pro-list row">
        <router-link
          :to="`/prodetail?id=${item.id}`"
          target="_blank"
          class="pro-item bg-white"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <img
            class="item-praise"
            src="@/assets/icon/icon-logo.png"
            alt=""
            :class="{ hide: commonData.mall_type }"
          />
          <div class="row-center">
            <el-image
              class="item-pro"
              :src="item.image"
              fit="cover"
              :alt="item.name"
              lazy
            ></el-image>
          </div>
          <div class="item-price row-center">
            <make-priceformat
              :price="item.min_price"
              :subscriptSize="18"
              :firstSize="18"
              :secondSize="18"
            >
            </make-priceformat>
            <!-- <span class="xs warn">价格登录显示</span> -->
          </div>
          <div class="item-title line2 nr normal mb5">
            {{ item.name }}
          </div>
          <!-- <div class="item-intro line1 xs muted">
                三合一 打印复印扫描 无线版...
              </div> -->
        </router-link>
      </div>
    </div>
  </article>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      dataList: [],
      //src: '',
    };
  },
  computed: {},
  methods: {
    /**
     * @description 获取首页数据
     * @return void
     */
    getIndex() {
      this.$api.getIndexDataApi().then((res) => {
        this.dataList = res.data.news;
      });
    },
  },
  created() {
    this.getIndex();
    //this.src= required(config.recommended_products);
  },
  computed: {
    ...mapState({
      commonData: (state) => state.user.commonData,
    }),
    ...mapState(["config"]),
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
// 推荐产品
.recommended {
  margin-top: 55px;
  margin-bottom: 66px;

  .recommended-l {
    width: 212px;
    height: 384px;

    .praise {
      top: 67px;
      left: 31px;
      width: 74px;
      height: 74px;
    }

    .info {
      top: 152px;
      left: 35px;

      .title {
        font-size: 30px;
        margin-bottom: 15px;
      }

      .sub-title {
        line-height: 1;
      }
    }
  }

  .recommended-r {
    .pro-list {
      .pro-item {
        width: 235px;
        height: 384px;
        padding: 18px 18px 0;
        line-height: 1;
        margin-right: 13px;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          .item-title {
            color: inherit;
          }
        }

        .item-praise {
          width: 50px;
          height: 50px;
          margin-bottom: 5px;
          &.hide {
            opacity: 0;
          }
        }

        .item-pro {
          width: 199px;
          height: 190px;
          margin: 0 auto 30px;
        }

        .item-price {
          margin-bottom: 10px;
        }

        .item-title {
          line-height: 1.2;
        }
      }
    }
  }
}
</style>
